import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { BackgroundImg } from '../BackgroundImg';

const LandingBackgroundHero05 = ({children}) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "backgrounds/landings/new/landing_05_hero_bg.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 90
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  return (
    <BackgroundImg image={data.desktop} style={{backgroundPosition: 'bottom'}}>
      {children}
    </BackgroundImg>
  )
}

export default LandingBackgroundHero05;

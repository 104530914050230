import * as React from "react";
import {Button, Container, Grid, Header, Segment} from "semantic-ui-react";
import "../../css/intro.css";
import Hero from '../pages/hero05';

import "animate.css/animate.compat.css";
import {navigateTo} from "../../helpers/router";
import {t} from "../../i18n";
import { StaticImage } from 'gatsby-plugin-image';
import LandingSection01 from "../parts/LandingSection01";
import LandingBackgroundSection05 from "../backgrounds/landings/Section05";
import LandingBackgroundSection02 from "../backgrounds/landings/Section02";
import LandingBackgroundSection03 from "../backgrounds/landings/Section03";
import LandingBackgroundSection04 from "../backgrounds/landings/Section04";
import IntroListItem from '../utils/IntroListItem';
import NewFooter from './newfooter';
import SEO from "../seo";

export default class Landing05 extends React.Component {
  render() {
    return (
      <Segment basic className="overall-segment">
        <SEO title="Suplementy na pamięć i koncentrację"/>
        <Hero/>
        <LandingSection01>
          <Header as={"h2"} id='intro-h2'>Suplementy <b>na pamięć</b>, których składniki zaczynają działać już po godzinie<sup>1</sup></Header>
          <p id={'section-01-01-lead'}>Masz problemy z zapamiętywaniem i ciężko Ci się skoncentrować? Przed Tobą wymagający czas w pracy przed komputerem albo sesja egzaminacyjna? A może ogólnie czujesz się przemęczony i znużony, a potrzeba Ci energii do myślenia?</p>
          <p>
            Oto suplementy na pamięć i koncentrację, które pomagają w utrzymaniu sprawności umysłowej niemal natychmiast.<sup>2</sup> Unikalna,
            opatentowana <b>formuła Cognivia łączy w sobie ekstrakty z dwóch roślin: szałwii lekarskiej i szałwii lawendolistnej.
            Skuteczność ich synergicznego działania została potwierdzona w aż 5 badaniach klinicznych.</b><sup>3</sup>
            Co istotne, te wyjątkowe suplementy dla mózgu zaczynają działać już po godzinie od przyjęcia pojedynczej porcji<sup>4</sup>, a efekty utrzymują się długotrwaleprzy dłuższym stosowaniu.
          </p>
        </LandingSection01>

        <LandingBackgroundSection02>
          <Segment basic id="section-01-02">
            <Container id="section-01-02-container">
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={8} verticalAlign="middle">
                    <Header as={"h2"} id='intro-h3'>Coś więcej niż “zwykłe” tabletki na pamięć. <b>Nasze suplementy są personalizowane</b></Header>
                    <p>
                      Suplementy na pamięć i koncentrację z formułą Cognivia, które proponuje nasza marka Pure4Me są w pełni personalizowane w
                      oparciu o odpowiedzi, jakich udzielasz nam w ankiecie. W ten sposób dobieramy odpowiednie proporcje dostosowane do Twojego wieku, wzrostu i masy ciała. Dzięki temu zyskujesz
                      pewność, że przygotowane dla Ciebie suplementy, zawierające składniki wspierające pracę mózgu w pełni pokryją Twoje dzienne zapotrzebowanie, tak aby przynieść Ci spodziewane rezultaty
                      najszybciej, jak to możliwe. To, co otrzymasz, to 30-dniowy zestaw wygodnych w użyciu saszetek. Przesłane one zostaną do Ciebiekurierem pod wskazany adres.
                    </p>
                  </Grid.Column>
                  <Grid.Column width={8} verticalAlign="middle">
                    <StaticImage src="../../img/landings/05/section_3.png" alt="Suplementy" placeholder="none"/>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        </LandingBackgroundSection02>

        <Segment basic style={{padding: "42px 0"}}>
          <Container>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <StaticImage src="../../img/landings/05/section_4.png" alt="Suplementy" placeholder="none"/>
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle">
                  <Header as={"h2"} id='intro-h3'>Jak działają suplementy <b>na pamięć i koncentrację</b> z formułą Cognivia?</Header>
                  <p>
                    Formuła Cognivia to roślinny nootrop, czyli substancja prokognitywna, która wzmacnia pamięć, uczenie się oraz
                    myślenie. Jest to unikalny suplement diety na pamięć, który wykorzystuje synergiczne połączenie ekstraktu z szałwii lekarskiej z
                    mikrokapsułkowanym olejem z szałwii lawendolistnej. Skuteczność działania formuły Cognivia wynika z jej wpływu na kinazę białkową
                    CamKII, której funkcjami są m.in. tworzenie i wzmacnianie synaps, synteza i uwalnianie neurotransmiterów, a także zwiększanie liczby dostępnych receptorów.
                  </p>
                  <p>
                    Aż 5 badań klinicznych udowodniło, że suplementy na pamięć i
                    koncentrację z tą formułą wykazują korzystny wpływ zarówno w pojedynczej dawce, jak i przy długotrwałym stosowaniu. Formuła
                    Cognivia poprawia funkcje pamięci roboczej oraz jakość pamięci. Powoduje również wzrost wydajności podczas wykonywania zadań umysłowych.
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>

        <LandingBackgroundSection03>
          <Segment basic id="section-01-03" style={{minHeight: 600}}>
            <Container id="section-01-03-container">
              <Grid stackable relaxed={"very"} centered>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticImage src='../../img/landings/05/ico_1.png' alt="Szałwia lekarska" placeholder='none' />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as={"h3"}>Ekstrakt z <b>szałwii lekarskiej</b></Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <p>
                            Szałwia lekarska jest używana od wieków ze
                            względu na jej wielorakie właściwości zdrowotne. Korzyści, jakie niesie dla funkcji
                            poznawczych i ich wydajności zostały szeroko przebadane. Aktywuje uwagę zadaniową,
                            pomagając się skoncentrować, już po godzinie od przyjęcia.<sup>6</sup>
                          </p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticImage src='../../img/landings/05/ico_2.png' alt="Szałwia lawendolistna" placeholder='none' />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as={"h3"}>Olej z <b>szałwii lawendolistnej</b></Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <p>
                            Szałwia lawendolistna poprawia pamięć długotrwałą i jakość pamięci w godzinę po
                            przyjęciu , a szybkość zapamiętywania po 2,5 godziny. Wspomaga także zdolność
                            koncentracji i wspiera utrzymanie optymalnego samopoczucia psychicznego.
                            Nasze suplementy na pamięć wykorzystują wyłącznie szałwię z upraw ekologicznych.
                          </p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticImage src='../../img/landings/05/ico_3.png' alt="Kwas pantotenowy" placeholder='none' />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as={"h3"}><b>Kwas pantotenowy</b></Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <p>
                            Kwas pantotenowy, czyli witamina B5, oddziaływuje na układ nerwowy, pomagając
                            w utrzymaniu sprawności umysłowej. To z jego acetylowej pochodnej, acetylo CoA, powstaje
                            neuroprzekaźnik zwany acetylocholiną, który odpowiada za koncentrację. Jest to składnik
                            suplementu diety na pamięć, który pomaga zmniejszyć uczucie zmęczenia i znużenia.
                          </p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row/>
              </Grid>
            </Container>
          </Segment>
        </LandingBackgroundSection03>

        <LandingBackgroundSection04>
          <Segment basic id="section-01-04">
            <Container id="section-01-04-container">

              <Grid stackable relaxed={'very'}>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Header as={'h2'} id='intro-h3'><b>Pure4me</b><br/> <b>Suplementy dla mózgu</b> dla osób pragnących poprawić swoją pamięć, koncentrację i zdolności poznawcze</Header>
                    <p> 
                      Wykazano, że formuła Cognivia pomaga w utrzymaniu sprawności umysłowej we wszystkich grupach wiekowych. Stąd nasze
                      suplementy diety na pamięć mogą być również szczególnie korzystne dla osób starszych, które chcą spowolnić stopniowe
                      obniżanie funkcji poznawczych, które nasilają się wraz z wiekiem. Warto wiedzieć, że formuła Cognivia została finalistą kategorii
                      “Składnik roku - Zdrowe starzenie” prestiżowego międzynarodowego konkursu NutraIngredients 2020.
                    </p>
                    <p id={'section-01-01-lead'}>Suplementy na pamięć i koncentrację z formułą Cognivia przeznaczone są dla:</p>
                    <div>
                      <IntroListItem><b>pracowników umysłowych</b> oraz osób zajmujących się <b>pracą kreatywną</b></IntroListItem>
                      <IntroListItem>menedżerów i kierowników <b>obciążonych obowiązkami</b></IntroListItem>
                      <IntroListItem>uczniów i studentów, którzy potrzebują <b>wsparcia w nauce</b></IntroListItem>
                      <IntroListItem>osób starszych, dla których istotnym jest <b>wzmocnić swoją pamięć i funkcje umysłowe</b></IntroListItem>
                      <IntroListItem>osób, które “żyją szybko”, <b>narażonych na znużenie i zmęczenie</b></IntroListItem>
                      <IntroListItem>osób, które zauważyły u siebie <b>trudności z koncentracją i zapamiętywaniem</b></IntroListItem>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={8} verticalAlign="middle">
                    <StaticImage src='../../img/landings/05/section_6.png' alt='Pozytywy suplementacji' placeholder='none' />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        </LandingBackgroundSection04>

        <LandingBackgroundSection05>
          <Segment basic id="section-01-05">
            <Container id="section-01-05-container">
              <Grid stackable relaxed={'very'}>
                <Grid.Column width={8} verticalAlign="middle">
                  <StaticImage src='../../img/landings/section_7.png' alt='Suplementy' placeholder='none'/>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header as={"h2"} id='intro-h2'>Zamów suplementy na pamięć i koncentrację z formułą Cognivia od Pure4Me</Header>
                  <p>
                    Poniżej znajduje się przygotowana przez nasz zespół naukowy ankieta -
                    jej wypełnienie nic nie kosztuje i nie zobowiązuje Cię do zakupu. Gdy skończysz odpowiadać na pytania, zaproponujemy Ci suplement diety
                    o spersonalizowanym składzie i jeśli zechcesz, będziesz mógł zamówić suplement z formułą Cognivia bezpośrednio przez stronę internetową.
                    Pure4Me to jedna z marek działającego od 25 lat producenta suplementów Natur Produkt Zdrovit. Zapewniamy, że wszystkie nasze preparaty są w pełni
                    bezpieczne, a unikalna technologia mikrokapsułkowania zapobiega utracie wartości ekstraktów.
                  </p>
                  <p><b>Popraw stan swojej pamięci i zdolności do koncentracji już dziś.</b></p>
                  <Grid>
                    <Button primary className="btn-primary shadow-light newintro-primary-button" id="btn-check"
                            onClick={() => navigateTo('/survey')}>
                      {t("sprawdź swój skład")}!
                    </Button>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Container>
          </Segment>
        </LandingBackgroundSection05>

        <Container className="landing-note-list">
        1. (1)N.T.J. TILDESLEY et al. Positive modulation of mood and cognitive performance following administration of acute doses of Salvia lavandulaefolia essential oil to healthy young volunteers. Physiology & Behavior 83 (2005) 699-709; (2)D.O. KENNEDY et al. Monoterpenoid extract
        of sage (Salvia lavandulaefolia) with cholinesterase inhibiting properties improves cognitive performance and mood in healthy adults. Journal of Psychopharmacology. (2011) 25(08) 1088-1100.<br />
        2. N.T.J. TILDESLEY et al. Positive modulation of mood and cognitive performance following administration of acute doses of Salvia lavandulaefolia essential oil to healthy young volunteers. Physiology & Behavior 83 (2005) 699-709; (2)D.O. KENNEDY et al. Monoterpenoid extract of
        sage (Salvia lavandulaefolia) with cholinesterase inhibiting properties improves cognitive performance and mood in healthy adults. Journal of Psychopharmacology. (2011) 25(08) 1088-1100.<br />
        3. (93001) N.T.J. TILDESLEY et al., 2005, Positive modulation of mood and cognitive performance following administration of acute doses of Salvia lavandulaefolia essential oil to healthy volunteers. Physiology & Behavior 83 (2005) 699-709<br/>
        (93002) D.O. KENNEDY et al, Effects of Cholinesterase Inhibiting Sage (Salvia officinalis) on Mood, Anxiety, and Performance on a psychological Stressor Battery. Neuropsychopharmacology (2006) 31, 845-852<br />
        (93003) A.B Scholey et al., An extract of Salvia (Sage) with anticholinesterase properties improves memory and attention in healthy older volunteers. Psychopharmacology (2008) 198: 127-139<br />
        (93004) N.T.J. TILDESLEY et al., Salvia lavandulaefolia (Spanish Sage) enhances memory in healthy young volunteers. Pharmacology, Biochemistry and Behavior (2003) 75: 669-674<br />
        (93005) D.O. KENNEDY et al., Monoterpenoid extract of sage (Salvia lavandulaefolia) with cholinesterase inhibiting properties improves cognitive performance and mood in healthy adults. Journal of Psychopharmacology. (2011) 25(930008): 1088-1100<br />
        (93006) NutriBrain research and technology transfert – Bordeaux (France)<br/>
        4. NutriBrain research and technology transfert – Bordeaux (France)<br/>
        5. (93001) N.T.J. TILDESLEY et al., 2005, Positive modulation of mood and cognitive performance following administration of acute doses of Salvia lavandulaefolia essential oil to healthy volunteers. Physiology & Behavior 83 (2005) 699-709<br />
        (93002) D.O. KENNEDY et al, Effects of Cholinesterase Inhibiting Sage (Salvia officinalis) on Mood, Anxiety, and Performance on a psychological Stressor Battery. Neuropsychopharmacology (2006) 31, 845-852<br />
        (93003) A.B Scholey et al., An extract of Salvia (Sage) with anticholinesterase properties improves memory and attention in healthy older volunteers. Psychopharmacology (2008) 198: 127-139<br />
        (93004) N.T.J. TILDESLEY et al., Salvia lavandulaefolia (Spanish Sage) enhances memory in healthy young volunteers. Pharmacology, Biochemistry and Behavior (2003) 75: 669-674<br />
        (93005) D.O. KENNEDY et al., Monoterpenoid extract of sage (Salvia lavandulaefolia) with cholinesterase inhibiting properties improves cognitive performance and mood in healthy adults. Journal of Psychopharmacology. (2011) 25(930008): 1088-1100<br />
        (93006) NutriBrain research and technology transfert – Bordeaux (France)<br />
        6. NutriBrain research and technology transfert – Bordeaux (France)<br />
        7. NutriBrain research and technology transfert – Bordeaux (France)<br />
        </Container>

        <span id="footer">
          <NewFooter/>
        </span>
      </Segment>
    );
  }
}
import * as React from "react"
import FullApp from "../FullApp";
import Layout from "../templates/layout";
import Landing05 from '../components/pages/landing05';

export default (props) => (
  <FullApp>
    <Layout {...props} router={props} isDark={true}>
      <Landing05 />
    </Layout>
  </FullApp>
);
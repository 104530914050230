import * as React from "react";
import {
  Button, Container, Grid, Header, Segment
} from "semantic-ui-react";
import {t} from "../../i18n";
import 'animate.css/animate.css'
import {navigateTo} from "../../helpers/router";
import LandingBackgroundHero05 from '../backgrounds/landings/Hero05';

export default class Hero extends React.Component {
  render() {
    return (
      <LandingBackgroundHero05>
        <Segment basic id="landing-3-banner" className="intro-fullwidth-component">
          <Container>
            <Segment basic id='hero-container-01'>
              <Grid stackable>
                <Grid.Column computer={8} mobile={16} tablet={16}>
                  <Header as="h1" id='hero-title'>Suplementy na <b>pamięć i koncentrację</b> z formułą Cognivia</Header>
                  <p id="hero-paragraph">Każdy z nas jest wyjątkowy i taki też jest Pure4Me. Personalizowany suplement diety, zawierający
                    składniki wpływające na pamięć i koncentrację z formułą Cognivia, to jedna z najlepszych propozycji
                    dla mózgu. Dowiedz się, w jaki sposób wzmocni Twoje funkcje poznawcze.</p>
                  <Grid>
                    <Button primary className="btn-primary shadow-light newintro-primary-button" id="btn-hero"
                            onClick={() => navigateTo('/survey')}>
                      {t("sprawdź swój skład")}!
                    </Button>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Segment>
          </Container>
        </Segment>
      </LandingBackgroundHero05>
    );
  }
}